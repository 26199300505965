import React, { createContext, useState, useEffect, useCallback } from 'react';
import callServerApi from "../Utils/apiManager";
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // Hook de react-router para redirección sin recarga

    const logoutAPI = useCallback(async () => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            try {
                await callServerApi('auth/logout', 'GET', {}, true);
            } catch (error) {
                console.error("La verificación del token falló", error);
            }
        }
    }, []);

    // Función para manejar el logout
    const logout = useCallback(() => {
        logoutAPI(); // Llamada para cerrar sesión en el servidor
        setToken(null); // Borrar el token del estado
        localStorage.clear(); // Limpiar el almacenamiento local
        navigate('/'); // Redirigir a la página principal sin recargar
    }, [logoutAPI, navigate]);

    useEffect(() => {
        const verifyToken = async () => {
            const storedToken = localStorage.getItem('token');
            if (storedToken) {
                try {
                    const response = await callServerApi('auth/verification-token', 'POST', {}, true);
                    if (response.status === 'ok' && response.code === 200) {
                        setToken(storedToken);
                    } else {
                        logout();
                    }
                } catch (error) {
                    console.error("La verificación del token falló", error);
                    logout();
                }
            }
            setLoading(false);
        };

        verifyToken();
    }, [logout]);

    const login = (token) => {
        setToken(token);
        localStorage.setItem('token', token);
    };

    return (
        <AuthContext.Provider value={{ token, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};
