import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import env from "../../helper/env.js";


const MetaTags = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const htmlLang = document.querySelector('html[lang]');
    if (htmlLang) {
      htmlLang.setAttribute('lang', localStorage.getItem('i18nextLng'));
    }
  },);

  return (
    <Helmet>
      {!env.isdev && <meta name="robots" content="index, follow" /> }
      <meta charset="utf-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
      <meta name="theme-color" content="#FFFFFF" />

      <link rel="icon" href={env.img_basepath + "brand/favicon.ico"} />
      <title>{t('meta.title')}</title>
      <meta name="description" content={t('meta.description')} />
      <meta name="keywords" content={t('meta.keywords')} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={env.base_url} />
      <meta property="og:title" content={t('meta.title')} />
      <meta property="og:description" content={t('meta.description')} />
      <meta property="og:image" content={env.img_basepath + "brand/logo.png"} />
      <link rel="apple-touch-icon" href={env.img_basepath + "brand/logo.png"}/>

      {/* FONTS */}
      {/* <link href={env.//fonts.googleapis.com/css?family=Nunito:400,700&display=swap" rel="stylesheet" />
      <link href="//fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap" rel="stylesheet"> */}
      {/* <link rel="stylesheet" href={env.base_url + 'assets/css/style-fonts.css'}></link> */}


    </Helmet>
  );
};

export default MetaTags;
