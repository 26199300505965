import React from 'react';
import { Translation } from 'react-i18next';
import callServerApi from '../Utils/apiManager'
import { Loading } from '../Common/Loading';
import ResponseAlert from "../Common/ResponseAlert";
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import Nav from 'react-bootstrap/Nav';

import 'react-phone-number-input/style.css';

class Register extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            providersData: null,
            providerSelected: null,
            responseError: '',
            responseSuccess: '',
            formData : {
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                fiscal_country: 'ES',
            },
            errors: {},
            show: true
        };
        this.acceptRef = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
    }

    validateForm() {
        let isValid = true;
        let errors = { ...this.state.errors };
        const validators = {
            email: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
            phone: /^\+[1-9]{1}[0-9]{3,14}$/
        };
        if (!this.acceptRef.current.checked) {
            errors['accept_terms'] = 'form.required';
            isValid = false;
        }
        Object.keys(this.state.formData).forEach(name => {
            // console.log('validateForm', name)
            if(validators[name]) {
                if (!this.state.formData[name]) {
                    isValid = false;
                    errors[name] = 'form.required';
                } else if(!validators[name].test(this.state.formData[name])) {
                    isValid = false;
                    errors[name] = (name === 'email') ? 'form.email invalid':'form.invalidField';
                } else {
                    errors[name] = '';
                }
            }
        });
        this.setState({ errors });
        return isValid;
    }

    handleChange(e) {
        let d = this.state.formData;
        d[e.target.name] = e.target.value;
        this.setState({ formData: d});
    }

    handlePhoneChange(value) {
        let d = this.state.formData;
        d.phone = value;
        this.setState({ formData: d });
    }

    handleCountryChange(value) {
        let d = this.state.formData;
        d.fiscal_country = value;
        this.setState({ formData: d});
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm()){
            this.setState({loading:true});
            const data = this.state.formData;
            callServerApi('auth/signup', 'POST', data)
                .then(result => {
                    if (result.status === 'ok') {
                       if (result.message) this.setState({ responseSuccess: result.message, show: false });
                    } else {
                        if (result.error) this.setState({ responseError: result.error });
                    }
                    this.setState({loading:false});
                    setTimeout(() => { this.setState({ responseError: '', responseSuccess:'' }); }, 5000);
                })
                .catch(error => {
                    this.setState({
                        responseError: error.message || '',
                        loading: false,
                    });
                    setTimeout(() => { this.setState({ responseError: '' }); }, 5000);
                });
        }
    }

    render() {
        const { loading, first_name, last_name, phone, email, errors, responseSuccess, responseError } = this.state;

        return (
            <Translation ns={['translation']}>
                { (t) => <div  className='map-content-9'>
                    {loading && <Loading/>}
                    <div>
                        {responseSuccess && <ResponseAlert response={responseSuccess} variant="success" />}
                        {responseError && <ResponseAlert response={responseError} variant="danger" />}

                        <form className="mt-1" onSubmit={this.handleSubmit}>
                            {this.state.show && <>
                            <div className="form-row">
                                <div className="col-12 col-lg-6 mb-3">
                                    <Form.Control  name="first_name"
                                        placeholder={t('register.name')}
                                        required
                                        value={first_name}
                                        onChange={this.handleChange}
                                    />
                                    {errors && errors.first_name && <Form.Control.Feedback type="invalid"  style={{display:'block'}}>{t(errors.first_name)} </Form.Control.Feedback> }

                                </div>
                                <div className="col-12 col-lg-6 mb-3">

                                    <Form.Control name="last_name"
                                        placeholder={t('register.last name')}
                                        required
                                        value={last_name}
                                        onChange={this.handleChange}
                                    />
                                      {errors && errors.last_name && <Form.Control.Feedback type="invalid"  style={{display:'block'}}>{t(errors.last_name)} </Form.Control.Feedback> }

                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-12 col-lg-6 mb-3">
                                    <Form.Control name="email"
                                        placeholder={t('register.email')}
                                        required
                                        value={email}
                                        onChange={this.handleChange}
                                    />
                                    {errors && errors.email && <Form.Control.Feedback type="invalid"  style={{display:'block'}}>{t(errors.email)} </Form.Control.Feedback> }

                                </div>
                                <div className="col-12 col-lg-6 mb-3">
                                    <PhoneInput
                                        flags={flags}
                                        placeholder={t('form.enter phone number')}
                                        name="phone"
                                        value={phone}
                                        onChange={this.handlePhoneChange}
                                        onCountryChange={this.handleCountryChange}
                                        className="form-control"
                                        defaultCountry="ES" // Opcional: establecer un país por defecto
                                        international // Mostrar número en formato internacional
                                    />
                                    {errors && errors.phone && <Form.Control.Feedback type="invalid" style={{display:'block'}}>{t(errors.phone)} </Form.Control.Feedback> }

                                 </div>

                            </div>
                            <div className="form-row">
                                <div className="col-12 mb-3">
                                    <input name="accept_terms" type="checkbox" className="form-check-input" ref={this.acceptRef}/>
                                    <label className="form-check-label">
                                        <Nav.Link className='p-0 text-dark' target="_blank" href="/privacy-policy">{t('register.accept')}</Nav.Link>
                                    </label>
                                    {errors && errors.accept_terms && <Form.Control.Feedback type="invalid"  style={{display:'block'}}>{t(errors.accept_terms)} </Form.Control.Feedback> }

                                </div>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-secondary btn-style mt-4">{t('signup')}</button>
                            </div>
                            </>}
                            <Nav className="justify-content-center" activeKey="/signup">
                                <Nav.Item>
                                    <Nav.Link href="/signin">{t("register.already have an account?")}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </form>

                    </div>
                </div>
                }
            </Translation>
        )
    }
}

export default Register;