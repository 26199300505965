import React from "react";
import {useTranslation} from "react-i18next";
import {useFormatString} from "../components/Utils/formatString";
import PublicBanner from './elements/PublicBanner';
import env from "../helper/env.js";
import { Helmet } from 'react-helmet';

function LegalNotice() {
    const {t} = useTranslation(['translation']);
    const formatString = useFormatString();
    const paragraph1 = t('legal notice.paragraph-1');
    const companyInformation = formatString('legal notice.company information');

    return (
        <>
            <Helmet><link rel="canonical" href={env.base_url + 'legal-notice'} /> </Helmet>
            <PublicBanner title="legal notice.title"/>

            <section className="w3l-aboutblock1 py-3 mb-5" id="legal-notice">
                <div className="container">
                    <h3 className="font-weight-bold mb-3">
                        {t("legal notice.information legal")}.
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph1}}></p>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: companyInformation}}></p>

                    <p className="mb-5">

                        {t("legal notice.paragraph-2")}
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                    </p>
                </div>
            </section>

        </>
    );

}

export default LegalNotice;