import React from 'react';
import RechargeComponent from "../components/Services/Recharge.js";
import PublicBanner from './elements/PublicBanner';
import env from "../helper/env.js";
import { Helmet } from 'react-helmet';

function Recharge() {
    return (
        <>
            <Helmet><link rel="canonical" href={env.base_url + 'recharge'} /> </Helmet>
            <PublicBanner title="Top up the balance of any phone in seconds"/>
            <section id="recharge" className="main-section home-services pt-lg-0 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 m-auto text-center ">
                            <RechargeComponent />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Recharge