import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactComponent from "../components/Contact.js";
import Accordion from 'react-bootstrap/Accordion';
import PublicBanner from './elements/PublicBanner';
import env from "../helper/env.js";
import { Helmet } from 'react-helmet';

function Contact() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet><link rel="canonical" href={env.base_url + 'contact'} /> </Helmet>
            <PublicBanner title="contact.title"/>

            <section id="contact" className="home-services pt-lg-0 mb-5">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-12 col-lg-8 py-lg-5 py-md-4">
                            <ContactComponent/>
                        </div>
                        <div className="col-12 col-lg-4 py-lg-5 py-md-4">
                            <h5 className='mb-sm-4 mb-3 font-weight-bold title-frequently-asked'>{t("frequently asked questions.title")}</h5>
                            <Accordion defaultActiveKey="0" flush>
                                <Accordion.Item eventKey="0" className="mt-3 mb-3">
                                    <Accordion.Header className="sub-title-frequently-asked">{t("frequently asked questions.how long will it take to receive my top-up")}</Accordion.Header>
                                    <Accordion.Body className="mt-3 mb-3">
                                        {t("frequently asked questions.top up a phone with us you choose the time as you can choose instant top ups or 2 hour waiting time top ups")}
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1" className="mt-3 mb-3">
                                    <Accordion.Header className="sub-title-frequently-asked">{t("frequently asked questions.can I top up a phone other than my own")}</Accordion.Header>
                                    <Accordion.Body className="mt-3 mb-3">
                                        {t("frequently asked questions.you simply need the mobile number you wish to send the money")}
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2" className="mt-3 mb-3">
                                    <Accordion.Header className="sub-title-frequently-asked">{t("frequently asked questions.will top-up arrive if the phone is roaming")}</Accordion.Header>
                                    <Accordion.Body className="mt-3 mb-3">
                                        {t("frequently asked questions.the recharge will be delivered directly to the line, regardless of its location")}
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3" className="mt-3 mb-3">
                                    <Accordion.Header className="sub-title-frequently-asked">{t("frequently asked questions.how to log in with a single-use code")}</Accordion.Header>
                                    <Accordion.Body className="mt-3 mb-3">
                                        {t("frequently asked questions.you can now log in to your registered")}
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4" className="mt-3 mb-3">
                                    <Accordion.Header className="sub-title-frequently-asked">{t("frequently asked questions.where is my card data stored")}</Accordion.Header>
                                    <Accordion.Body className="mt-3 mb-3">
                                        {t("frequently asked questions.for security reasons, we do not handle payments directly")}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <a href="/faqs" className="btn btn-link pl-0"> Leer mas</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact