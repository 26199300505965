import React from "react";
import PrivateBanner from './elements/PrivateBanner';
import env from "../helper/env.js";
import { useTranslation } from 'react-i18next';


export default function PaymentConfirmationKO(props) {
    const { t } = useTranslation();
    return (
        <>
            <PrivateBanner />
            <section className="w3l-aboutblock1 py-5" id="about">
                <div className="container py-lg-5 py-md-4 py-2">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 left-wthree-img mt-lg-0 mt-sm-5 mt-4">
                            <img src={env.img_basepath + "payment-ko.jpg"} alt="KO" className="img-fluid radius-image mb-5" loading="lazy"/>
                            <h3 className="title-big text-center">{t("paymentko")}</h3>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

