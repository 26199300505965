import React from "react";
import PrivateBanner from '../elements/PrivateBanner';
import OrderHistoryComponent from "../../components/Client/OrderHistory";

export default function History(props) {
    return (
        <>
            <PrivateBanner title="account.history"/>
            <section id="account" className="private-area bg-light">
                <div className="container pt-5">
                    <OrderHistoryComponent onlyRecent={false}/>
                </div>
            </section>
        </>
    )

}