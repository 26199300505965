import variables from "../../helper/env";
import i18next from 'i18next';

const callServerApi = (endpoint, method = 'GET', data = null, auth = false) => {
    let url = variables.backend_url + endpoint;

    if (method === 'GET' && data) {
        const params = new URLSearchParams({ ...data, locale: i18next.language }).toString();
        url = `${url}?${params}`;
    }

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    if (auth) {
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    }

    const options = {
        method,
        headers
    };

    if ((method === 'POST' || method === 'PUT' || method === 'DELETE') && data) {
        options.body = JSON.stringify({ ...data, locale: i18next.language });
    }

    return fetch(url, options)
        .then(response => {
            return response.json()
                .then(json => {
                    if (!response.ok) {
                        const error = new Error(json.message || 'Server response not OK');
                        error.response = response;
                        error.data = json;
                        throw error;
                    }
                    return json;
                });
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export default callServerApi;