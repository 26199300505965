import React from 'react';
import PrivateBanner from '../elements/PrivateBanner';

import DashboardComponent from "../../components/Client/Dashboard";

export default function Account() {
    return (
        <>
            <PrivateBanner title="account.my account"/>
            <section id="account" className="private-area bg-light">
                <div className="dashboard container">
                    <DashboardComponent/>
                </div>
            </section>
        </>
    )
}