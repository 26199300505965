import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import env from "../../helper/env.js";

function PrivateBanner(props) {
    const { t } = useTranslation();
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');

    useEffect(() => {
        setTitle(props.title);
        setSubtitle(props.subtitle);
    }, [props]);

    return (
        <section className="w3l-breadcrumb">
            <div className="breadcrumb breadcrumb-bg" style={{backgroundImage:`url(${env.img_basepath}banner_1.jpg)`}}>
                { title && <div className="container text-center">
                    <h2 className="title">
                        {t(title)}
                        {subtitle && <span> <br/>{t(subtitle)}</span>}
                    </h2>
                </div>}
            </div>

        </section>
    )
}
export default PrivateBanner