
const variables = {
    app_name: process.env.REACT_APP_NAME,
    lang: 'es',
    isdev: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'),
    mienv: 'development',
    base_url: process.env.REACT_APP_URL,
    backend_url: process.env.REACT_APP_BACKEND_URL,
    img_basepath: process.env.REACT_APP_IMG_BASEPATH,
    name_company: process.env.REACT_APP_NAME_COMPANY
}

export default variables;