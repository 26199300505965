import React from 'react';
import PrivateBanner from './elements/PrivateBanner';
import { useParams } from 'react-router-dom';
import {t} from "i18next";

const Unsubscribe = () => {
    const { result } = useParams();
    return (
        <>
            <PrivateBanner title=""/>
            <section className="w3l-aboutblock1 py-5 d-flex align-items-center" id="about" style={{ minHeight: '70vh' }}>
                <div className="container d-flex justify-content-center align-items-center py-lg-5 py-md-4 py-2" style={{ minHeight: '50vh' }}>
                    <div className="col-lg-8 text-center p-4" style={{
                        border: '2px solid #7bae37',
                        borderRadius: '10px',
                        padding: '40px',
                        backgroundColor: '#f9f9f9',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                    }}>
                        <h2 style={{fontSize: '28px', fontWeight: 'bold', color: '#333', marginBottom: '20px'}}>
                            { t("unsubscribe."+result)}
                        </h2>
                        <p style={{fontSize: '16px', color: '#555', marginBottom: '30px'}}>
                            {t("unsubscribe.description")}
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Unsubscribe;
