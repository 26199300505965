import React from 'react';
import PrivateBanner from '../elements/PrivateBanner';
import BeneficiariesComponent from "../../components/Client/Beneficiaries";

export default function Beneficiaries() {
    return (
        <>
            <PrivateBanner title="beneficiaries.title"/>
            <section id="account" className="private-area bg-light">
                <div className="container pt-5">
                    <BeneficiariesComponent/>
                </div>
            </section>
        </>
    )

}

