import {useTranslation} from "react-i18next";

export function useFormatString() {
    const { t } = useTranslation(["translation"]);

    return (key) => {
        const text = t(key);
        const parts = text.split("\n");
        let formattedText = "";

        parts.forEach((part, idx) => {
            formattedText += `${part}<br/>`;
        });

        return formattedText;
    };
}