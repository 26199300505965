import React from "react";
import {useTranslation} from "react-i18next";
import {useFormatString} from "../components/Utils/formatString";
import PublicBanner from './elements/PublicBanner';
import env from "../helper/env.js";
import { Helmet } from 'react-helmet';

function CookiePolicy() {
    const {t} = useTranslation(['translation']);
    const formatString = useFormatString();
    const paragraph1 = formatString('cookie-policy.paragraph-1');
    const paragraph3 = formatString('cookie-policy.paragraph-3');
    const paragraph4 = formatString('cookie-policy.paragraph-4');
    const paragraph6 = formatString('cookie-policy.paragraph-6');

    return (
        <>
            <Helmet><link rel="canonical" href={env.base_url + 'cookie-policy'} /> </Helmet>
            <PublicBanner title="cookie-policy.title"/>

            <section className="w3l-aboutblock1 py-3" id="privacy-policy">
                <div className="container">
                    <h3 className="font-weight-bold mb-3">
                        {t("cookie-policy.cookie statement")}
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph1}}></p>

                    <h3 className="font-weight-bold mb-3">
                        {t("cookie-policy.what are cookies")}
                    </h3>

                    <p className="mb-4">{t("cookie-policy.paragraph-2")}</p>

                    <h3 className="font-weight-bold mb-3">
                        {t("cookie-policy.how we use cookies")}
                    </h3>


                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph3}}></p>

                    <h3 className="font-weight-bold mb-3">
                        {t("cookie-policy.types of cookies")}
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph4}}></p>

                    <h3 className="font-weight-bold mb-3">
                        {t("cookie-policy.your consent")}
                    </h3>

                    <p className="mb-4">{t("cookie-policy.paragraph-5")}</p>

                    <h3 className="font-weight-bold mb-3">
                        {t("cookie-policy.how to manage cookies")}
                    </h3>

                    <p className="mb-4" dangerouslySetInnerHTML={{__html: paragraph6}}></p>

                    <h3 className="font-weight-bold mb-3">
                        {t("cookie-policy.can turecargas.com  change its cookie policy")}
                    </h3>

                    <p className="mb-5">{t("cookie-policy.paragraph-7")}</p>


                </div>
            </section>

        </>
    );
}

export default CookiePolicy;