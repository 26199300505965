import React from 'react';
import { Translation } from 'react-i18next';
import callServerApi from '../Utils/apiManager';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Nav from 'react-bootstrap/Nav';
import ResponseAlert from '../Common/ResponseAlert';

import { Loading } from '../Common/Loading';
import { AuthContext } from './AuthContext';

const { jsHelper } = require('../../helper/fn.js');


class Login extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
        const codeParam = urlParams.get('code');

        this.state = {
            loading: false,
            providersData: null,
            providerSelected: null,
            subjectContact: '',
            responseError: '',
            responseSuccess: codeParam || '',
            formData: {
                email: '',
                code: codeParam || ''
            },
            errors: {},
            codeTmpArray: ['', '', '', '', '', '']
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCode = this.handleChangeCode.bind(this);


    }
    componentDidMount = () =>{
        const token = localStorage.getItem('token');
        if (token) {
            this.redirectAfterLogin();
        } else {

            if (this.state.formData.code.length > 0) {
                const s = this.state.formData.code.split('');
                this.setState({ codeTmpArray: s });
                this.sendCode(this.state.formData.code)
            }
        }

    }

    handleChangeCode(e) {
        const { name, value } = e.target;
        const index = parseInt(name.replace('code_', ''), 10);
        const newCodeTmpArray = [...this.state.codeTmpArray];
        newCodeTmpArray[index] = value;

        const newCode = newCodeTmpArray.join('');

        if (newCode.length === 6) {
           this.sendCode(newCode);
        } else {
            this.setState({
                codeTmpArray: newCodeTmpArray,
                formData: { ...this.state.formData, code: newCode }
            }, () => {
                const nextField = document.querySelector(`.inputcode[name=code_${index + 1}]`);
                if (nextField && value) nextField.focus();
            });
        }
    }

    handleKeyUpCode = (e) => {

        const { name } = e.target;
        const index = parseInt(name.replace('code_', ''), 10);
        if (e.key === 'ArrowLeft' && index > 0) {
            e.preventDefault();
            const nextField = document.querySelector(`.inputcode[name=code_${index - 1}]`);
            if (nextField) nextField.focus();
        } else if (e.key === 'ArrowRight' && index < 5) {
            e.preventDefault();
            const nextField = document.querySelector(`.inputcode[name=code_${index + 1}]`);
            if (nextField) nextField.focus();
        }else if (e.key === 'Backspace' && index > 0) {
            e.preventDefault();
            const nextField = document.querySelector(`.inputcode[name=code_${index - 1}]`);
            if (nextField) {
                nextField.value = '';
                nextField.focus();
            }
          }
    }
    sendCode(c){
        this.setState({
            formData: { ...this.state.formData, code: c },
            loading: true
        }, () => {
            const data = this.state.formData;
            callServerApi('auth/verification-login', 'POST', data)
                .then(result => {
                    if (result.status === 'ok' && result.data.token) {
                        this.context.login(result.data.token);
                        this.redirectAfterLogin();

                    } else {
                        this.handleError(result.message);
                    }
                })
                .catch(error => {
                    this.handleError(error.message);

                });
        });
    }
    handleError(message) {
        this.setState({
            responseError: message || '',
            loading: false,
            codeTmpArray: ['', '', '', '', '', '']
        });
        const firstField = document.querySelector(`.inputcode[name=code_0]`);
        if (firstField) firstField.focus();
        setTimeout(() => { this.setState({ responseError: '' }); }, 5000);
    }
    redirectAfterLogin(){
        // *redirection
        const _from = localStorage.getItem('from');
        if (_from) {
            if (_from !== 'recharge') jsHelper.clearClientStorage();
            window.location.href = '/'+_from;
        }else  window.location.href = '/account';
    }

    validateForm() {
        let isValid = true;
        let errors = { ...this.state.errors };
        const validators = {
            email: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
        };

        Object.keys(this.state.formData).forEach(name => {
            if (validators[name]) {
                if (!this.state.formData[name]) {
                    isValid = false;
                    errors[name] = 'form.required';
                } else if (!validators[name].test(this.state.formData[name])) {
                    isValid = false;
                    errors[name] = 'form.email invalid';
                } else {
                    errors[name] = '';
                }
            }
        });
        this.setState({ errors });
        return isValid;
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm()) {
            this.setState({ loading: true });
            const data = this.state.formData;
            callServerApi('auth/login', 'POST', data)
                .then(result => {
                    if (result.status === 'ok') {
                        this.setState({responseSuccess: result.message || '', loading: false});
                    } else {
                        this.setState({
                            responseError: result.error || '',
                            loading: false
                        });
                        setTimeout(() => { this.setState({ responseError: '' }); }, 5000);
                    }
                })
                .catch(error => {
                    this.setState({
                        responseError: error.message || '',
                        loading: false
                    });
                    setTimeout(() => { this.setState({ responseError: '' }); }, 5000);
                });
        }
    }

    resetformData = () =>{
        this.setState({
            formData: { email: '', code:'' },
            responseSuccess:''
        });
    }


    render() {
        const { loading, formData, errors, responseSuccess, responseError, codeTmpArray } = this.state;
        return (
            <Translation ns={['translation']}>
                {(t) => (<>
                    <div className='map-content-9'>
                        {loading && <Loading/>}
                        {!responseSuccess &&
                            <div>
                                {responseError && <ResponseAlert response={responseError} variant="danger" />}
                                <p className="text-justify mb-4">{t('login.we will send you a code to your email for you to log in')}.</p>
                                <form className="mt-1" onSubmit={this.handleSubmit}>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            name="email"
                                            placeholder={t('login.email')}
                                            required
                                            onChange={this.handleChange}
                                            autoFocus={true}
                                        />
                                        {errors.email && <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>{t(errors.email)}</Form.Control.Feedback>}
                                    </InputGroup>
                                    {/* <div className="d-flex align-items-center my-3">
                                        <hr className="flex-grow-1" />
                                        <span className="mx-2 text-2 text-muted">{t('login.or login with')} {t('login.social profile')}</span>
                                        <hr className="flex-grow-1" />
                                    </div> */}
                                    <div className="d-flex flex-column align-items-center mb-3"></div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary btn-style mt-4">{t('next')}</button>
                                    </div>
                                </form>
                            </div>}
                        {responseSuccess &&
                            <div className='text-center'>
                                {responseError && <ResponseAlert response={responseError} variant="danger" />}
                                <Form.Label htmlFor="inputPassword5">{t('login.please enter the verification code we have sent you') + ': '}</Form.Label>
                                <div><b>{formData.email}</b></div>
                                <div className='inputcode-wrapper'>
                                    {codeTmpArray.map((c, i) => (
                                        <Form.Control
                                            key={i}
                                            name={`code_${i}`}
                                            value={c} maxLength={1}
                                            className='inputcode'
                                            required
                                            onChange={this.handleChangeCode}
                                            onKeyUp={this.handleKeyUpCode}
                                            autoFocus={(i === 0)}
                                        />
                                    ))}
                                </div>

                            </div>}
                    </div>
                     {responseSuccess && <Nav className="justify-content-between nav mt-5" activeKey="/signup">
                        <Nav.Item>
                            <Nav.Link className="text-underline" onClick={this.handleSubmit}>{t('login.resend code')} <i className="fa fa-angle-right" aria-hidden="true"></i></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link className="text-underline"  onClick={this.resetformData}>{t('login.reset email')} <i className="fa fa-angle-right" aria-hidden="true"></i></Nav.Link>
                        </Nav.Item>
                    </Nav>
                   }
                    </>

                )}
            </Translation>
        );
    }
}

export default Login;