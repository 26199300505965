import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function RequireAuth(Component) {
    return function AuthenticatedComponent(props) {
        const navigate = useNavigate();
        const token = localStorage.getItem('token');

        useEffect(() => {
            if (!token) {
                navigate('/signin');
            }
        }, [token, navigate]);

        return token ? <Component {...props} /> : null;
    }
}