import React from 'react';
import PrivateBanner from './elements/PrivateBanner';
import env from "../helper/env.js";

const NotFound = () => {
    return (
        <>
            <PrivateBanner title=""/>
            <section className="w3l-aboutblock1 py-5" id="about">
                <div className="container py-lg-5 py-md-4 py-2">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 left-wthree-img mt-lg-0 mt-sm-5 mt-4">
                            <img src={env.img_basepath + "404.jpg"} alt="404" className="img-fluid radius-image mb-5" loading="lazy"/>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default NotFound;
