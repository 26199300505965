import React, { useContext, useEffect,  } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from './AuthContext';

function Logout() {
    const authContext = useContext(AuthContext);

    useEffect(() => {
        authContext.logout();
    }, [authContext]);

    return  <Navigate to="/signin" />
}

export default Logout;