import React from 'react';
import i18n from './i18n';
import getLanguage from '../language/getLanguage';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
const lang =  getLanguage();

const LanguageSelector = () => {  
  const languages = [{label:'Español', value:'es'}, {label:'English', value:'en'}];

  const changeLanguage = (value) => {
    window.localStorage.i18nextLng = value;
    i18n.changeLanguage(value);
    window.location.reload();
  }

  const getLabel = () =>{
    const language = languages.find(l => l.value === lang);
    return ((language) ? language.label : languages[0].label );
  }
  
  return (
   
    <Nav className="me-auto">
      <NavDropdown  
        
        title={  getLabel() } id="change-language">
        {languages.map((d, index) => {
            return <NavDropdown.Item key={index} className="d-flex" href="#" onClick={() => changeLanguage(d.value)}>
                <span className='col'>{d.label}</span>
            </NavDropdown.Item>
        })}        
      
      </NavDropdown>
    </Nav>
    )

  
}

export default LanguageSelector
