import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal} from 'react-bootstrap';

function ConfirmationModal(props) {
    const { t } = useTranslation();
    const { show, handleClose, handleConfirm, size, modalHeader, modalBody } = props;

    return (
        <Modal onClose={handleClose} size={size} show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalBody}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                {t('No')}
            </Button>
            <Button variant="primary" onClick={handleConfirm}>
                {t('Yes')}
            </Button>
            </Modal.Footer>
        </Modal>
    );
 
}

export default ConfirmationModal;