import env from "./env.js";

const jsHelper = {
    scrollToTop: () =>{
        window.scrollTo(0,0);
    },
    clearClientStorage: () => {
        localStorage.removeItem('from');
        localStorage.removeItem('prefixObj');
        localStorage.removeItem('phoneNumber');
        localStorage.removeItem('providerObj');
        localStorage.removeItem('beneficiaryObj');
    },
    patterPhoneNumberToSend: (prefix,phoneNumber) =>{ console.log('patterPhoneNumberToSend', prefix,phoneNumber);
        const plus = (prefix && !String(prefix).startsWith('+')) ? '+' : '';
        const _pn =  String(phoneNumber).replace(/ /g, '');
        const r = plus + prefix + _pn;
        return r;
    },
    getPhoneNumberWithOutPrefix: (prefix,phoneNumber) =>{
        const plus = (prefix && !prefix.startsWith('+')) ? '+' : '';
        const _pn =  String(phoneNumber).replace(/ /g, '');
        const r = String(_pn).replace(plus+prefix,'');
        return r;
    },
    sendRecharge: (data, navigate) =>{
        console.log('sendRecharge', data)
        if (data && data.phone_number) data.phone = data.phone_number;
        if (!data || !data.phone) return false;

        let phoneNumber;
        /*localstorage *recharge */
        if (data.telephone_prefixes){
            const r = data.telephone_prefixes[0];
            const prefixObj = {
                img: env.img_basepath + r.img_flag,
                label:r.country_name,
                rlabel:`+${r.prefix}`,
                value:`+${r.prefix}`,
                max_length:r.max_length,
                min_length:r.min_length,
                country_iso: r.country_iso
            };
            localStorage.setItem('prefixObj',JSON.stringify(prefixObj));
            phoneNumber = data.phone.replaceAll(`+${r.prefix}`, '');
        }else{
            phoneNumber = data.phone;
        }

        localStorage.setItem('phoneNumber', phoneNumber);
        if (data.provider && data.provider_name){
            localStorage.setItem('providerObj', JSON.stringify({provider_code:data.provider, name:data.provider_name}));
        }
        if (data.beneficiary && data.beneficiary.name) localStorage.setItem('beneficiaryObj',JSON.stringify({name:data.beneficiary.name}));
        // Utiliza la función navigate proporcionada
        if (navigate) {
            navigate('/recharge');
        } else {
            window.location.href = '/recharge';
        }
    }


}
export default jsHelper;