import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import callServerApi from "../Utils/apiManager";
import RequireAuth from '../Auth/RequireAuth';

import PersonalInformation from './PersonalInformation';
import OrderHistoryComponent from "./OrderHistory";
import BeneficiariesComponent from "./Beneficiaries";

import { Loading } from '../Common/Loading';

import helper from "../../helper/fn.js";


class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            activeComponent: null,
            email:'', first_name:'', phone:'',
            errors: {},
        };
    }

    componentDidMount = () =>{
        callServerApi('client/profile', 'POST', {}, true)
        .then(result => {
            if (result.status ==='ok' && result.data[0]){
                this.setState({
                    email:result.data[0].email,
                    first_name:result.data[0].first_name,
                    phone:result.data[0].phone,
                    loading:false
                });
                if (!result.data[0].first_name || !result.data[0].phone){
                    this.setActiveComponent('personalInformation');
                }else{
                    this.setActiveComponent(null);
                }
            }
        })
        .catch(error => {
            console.log(error.message);
        });
    }

    setActiveComponent = (name) =>{
        this.setState({activeComponent:name});
    }

    logout = () =>{
        window.location.href = '/logout';
    }

    render() {
        const { loading, activeComponent, email, first_name} = this.state;
        return (
            <Translation ns={['translation']}>
                { (t) => <>
                    {loading && <Loading/>}
                    {!activeComponent && <>
                        <div className='row'>
                            <div className='col-12 col-lg-6 mb-4'>
                                <div className="cont-top">
                                    <div className="cont-left text-center">
                                        <div className='icon-box'>
                                            <span className="fa fa-user"></span>
                                        </div>
                                    </div>
                                    <div className="cont-right">
                                        <h6>{(first_name)?first_name:email}</h6>
                                        <p className="pr-lg-5">
                                            {activeComponent !== 'personalInformation' && <button className="btn pl-0 " onClick={() => this.setActiveComponent('personalInformation')}>
                                                {t("account.personal information")} <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </button>}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6 text-center text-lg-right'>
                                <a className="btn btn-style btn-secondary font-size-1" href="/recharge" onClick={helper.clearClientStorage}>
                                    {t("Ready to send a top-up?")}
                                </a>
                            </div>

                        </div>

                        <div className='row mt-5 mb-5'>
                            <div className='col-12 col-lg-8'>
                                <div className="map-content-9 mb-4 pt-4 dashboard-history">
                                    <OrderHistoryComponent dashboard={true}/>
                                </div>
                                <div className='row d-none'>
                                    <div className='col-12 col-lg-6'>
                                        <div className="map-content-9 mb-4">
                                            {/* mas informacion aqui */}
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-6'>
                                        <div className="map-content-9 mb-4">
                                            {/* mas informacion aqui */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4'>
                                <div className="map-content-9 mb-4 pt-4 dashboard-beneficiaries">
                                    <BeneficiariesComponent dashboard={true}/>
                                </div>
                                <div className="map-content-9 mb-4 d-none">
                                    {/* mas informacion aqui */}
                                </div>
                            </div>
                        </div>
                    </>}

                    {activeComponent && <>
                        <div className='row'>
                            <div className='col-12 mb-4'>
                                <div className="cont-top">
                                    <div className="cont-left text-center">
                                        <div className='icon-box'>
                                            <span className="fa fa-cubes"></span>
                                        </div>
                                    </div>
                                    <div className="cont-right">
                                        <p className="pr-lg-5">
                                            <button className="btn btn-link pl-0" onClick={() => this.setActiveComponent(null)}>
                                                <i className="fa fa-angle-left" aria-hidden="true"></i> {t("account.dashboard")}
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {activeComponent === 'personalInformation' && <PersonalInformation/> }
                    </>}
                    {!activeComponent &&
                    <div className='text-center m-5'>
                        <a href="/" onClick={this.logout} className='nav-link font-size-1'> {t("login.logout")} </a>
                    </div>
                    }
                </>
                }
            </Translation>
        )
    }
}

export default withTranslation()(RequireAuth(Dashboard));