import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import env from "../../helper/env.js";

function PublicBanner(props) {
    const { t } = useTranslation();
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [paragraph, setParagraph] = useState('');
    const [link, setLink] = useState('');
    const [linkText, setLinkText] = useState('');
    const [linkClass, setLinkClass] = useState('');

    useEffect(() => {
        setTitle(props.title);
        setSubtitle(props.subtitle);
        setParagraph(props.paragraph);
        setLink(props.link);
        setLinkText(props.linkText);
        setLinkClass(props.linkClass);
    }, [props]);

    return (
        <section className="w3l-breadcrumb">
            <div className="breadcrumb breadcrumb-bg" style={{backgroundImage:`url(${env.img_basepath}banner_1.jpg)`}}>
                <div className="container p-lg-4 pb-lg-5 text-center mb-5">
                    <h2 className="title mb-0 h3">
                        {t(title)}
                        {subtitle && <span> <br/>{t(subtitle)}</span>}
                    </h2>
                    {paragraph && <p className="mt-2 pr-lg-2 text-white">
                        {t(paragraph)}
                    </p>}
                    {link && linkText && <a className={linkClass} href={link}> {t(linkText)} </a> }
                </div>
            </div>
            <div className="waveWrapper waveAnimation">
                <svg viewBox="0 0 500 150" preserveAspectRatio="none">
                    <path d="M-5.07,90 C150,130 350,20 500,90 L500,150 L0,150 Z"></path>
                </svg>
            </div>
        </section>
    )
}
export default PublicBanner