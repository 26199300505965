import React from 'react';
import {Translation, withTranslation} from 'react-i18next';
import callServerApi from "../Utils/apiManager";
import RequireAuth from '../Auth/RequireAuth';
import DataTable, { createTheme } from 'react-data-table-component';
import { Loading } from '../Common/Loading';
import StatusIcon from '../Common/StatusIcon';
import Nav from 'react-bootstrap/Nav';

import env from "../../helper/env.js";
import helper from "../../helper/fn.js";

class OrderHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            columns :  [],
            data:[],last_orders:null,
            errors: {},
            dashboard: !!(props.dashboard),
            pagination: (!props.dashboard),
        };
    }

    componentDidMount = () =>{
        const fnNext = (result) => {
            if (result.status ==='ok' && result.code === 200){
                const columns = result.data.columns.map((column) => ({
                    ...column,
                    selector: row => {
                        if (column.selector === 'status') {
                            return <StatusIcon status={row.status} />;
                        } else {
                            return row[column.selector];
                        }
                    },
                }))
                this.setState({
                    data : result.data.orders,
                    columns,
                    loading:false,
                    last_orders: (result.data.orders && result.data.orders.length > 0) ? result.data.orders.slice(0, 3)  : null
                })
            }
        }
        callServerApi('order-history', 'POST', {onlyRecent: this.state.dashboard}, true)
            .then(fnNext)
            .catch(error => {
                this.setState({
                    loading: false,
                    errors: error
                });
            });
    }
    sendRecharge = (data) =>{
        let dataToSend = data.repeat;
        dataToSend.phone = data.phone;
        helper.sendRecharge(dataToSend);
    }
    render() {
        const { loading, columns, data, last_orders, pagination, dashboard} = this.state;
        createTheme('solarized', {
            text: {
                primary: '#268bd2',
                secondary: '#2aa198',
            },
            background: {
                default: '#e1ebc9',
            },
            context: {
                background: '#cb4b16',
                text: '#FFFFFF',
            },
            divider: {
                default: '#073642',
            },
            action: {
                button: 'rgba(0,0,0,.54)',
                hover: 'rgba(0,0,0,.08)',
                disabled: 'rgba(0,0,0,.12)',
            },
        }, 'dark');
        return (
            <Translation ns={['translation']}>
                { (t) => <div id='client_orderhistory'>
                    {loading && <Loading/>}

                    <div className='row'>
                        <div className='col-12 text-center data_table'>
                            {!loading && data && data.length === 0 && <>
                                <img className="image-shadow" src={env.img_basepath +"img-desert-skyline.webp"} alt="Status"/>
                                <h4>Ohhh... {t("history.you have not made any recharges yet")}</h4>
                            </>
                            }

                            {!loading  && data && data.length > 0 && <>

                                {/*ultimas recargas */}

                                { dashboard && <>
                                    <div className='d-lg-flex d-none justify-content-between align-items-center mb-2'>
                                        <div className='font-weight-bold col-6 text-left'>{t("account.recent activity")}</div>
                                        <Nav className="justify-content-end nav col-6 text-right">
                                            <Nav.Link href="/history">{t("account.order history")} <i className="fa fa-angle-right " aria-hidden="true"></i></Nav.Link>
                                        </Nav>
                                    </div>
                                    <div className='d-lg-none d-block align-items-center mb-2'>
                                        <div className='font-weight-bold col-12 text-center p-0'>{t("account.recent activity")}</div>
                                        <Nav className="nav col-12 text-center d-block w-100 p-0">
                                            <Nav.Link href="/history">{t("account.order history")}</Nav.Link>
                                        </Nav>
                                    </div>
                                    {last_orders.map(x => (
                                        <div className="beneficiary-card card text-center mb-4" key={x.id}>
                                            <div className=" card-body pt-3 pb-3">
                                                <div className='row m-0'>
                                                    {x.repeat &&  x.repeat.telephone_prefixes && x.repeat.telephone_prefixes.length > 0 &&(
                                                        <div className="col-8 col-lg-3 justify-content-center" style={{display:'flex'}}>
                                                            <div className="flag d-flex justify-content-center align-items-center rounded-circle p-0 cicle-provider-beneficiaries"
                                                                style={{
                                                                    backgroundImage: `url(${env.img_basepath}flags/1x1/${x.repeat.telephone_prefixes[0].country_iso.toLowerCase()}.svg)`,
                                                                }}>
                                                            </div>
                                                            <div className="ml-2 font-weight-bold text-truncate">{x.phone}</div>
                                                        </div>
                                                    )}
                                                    <div className="col-4 col-lg-3 text-right text-lg-center pl-0"><span className='d-none d-lg-block'> {x.date}</span><span className='d-block d-lg-none' style={{fontSize:'0.8em'}}> {x.date}</span></div>
                                                    <div className="col-12 col-lg-3 d-none d-lg-block">{x.receive_value}</div>
                                                    <div className="col-12 col-lg-3 mt-4 mt-lg-0 text-center">
                                                        <button className="btn btn-outline-secondary font-size-1 "
                                                            onClick={() => this.sendRecharge(x) }>
                                                            {t("Recharge")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>}
                                { !dashboard && <>
                                    <div className='d-flex justify-content-between align-items-center mb-2'>
                                        <Nav className="justify-content-right nav">
                                            <Nav.Link href="/account" className='d-flex align-items-center'>
                                                <div className='icon-box mr-1'><span className="fa fa-cubes"></span> </div>
                                                <div className='mr-1'><i className="fa fa-angle-left" aria-hidden="true"></i> </div>
                                                {t("account.dashboard")}
                                            </Nav.Link>
                                        </Nav>
                                    </div>
                                    <DataTable
                                        theme="solarized"
                                        columns={columns}
                                        data={data}
                                        pagination={pagination}
                                    />
                                </>
                                }


                            </>}
                        </div>
                    </div>
                </div>
                }
            </Translation>
        )
    }
}

export default withTranslation()(RequireAuth(OrderHistory));