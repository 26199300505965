import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import getLanguage from './getLanguage';
const lang = getLanguage();
const appURL = process.env.REACT_APP_URL;

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'es',
        debug: true,
        lng: lang,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
          loadPath: appURL+'locales/{{lng}}/{{ns}}.json',
        },
      });


export default i18n;